<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterOrders"
      @clear="clearFilter"
      currentComponent="orders-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>{{ item.id }}</td>
        <td class="text-center">
          <div>{{ item.approved_registration_date | formatDate }}</div>
          <div>{{ item.approved_registration_date | formatTime }}</div>
        </td>
        <td>{{ item.company_name }}</td>
        <td>
          <ul class="pa-0">
            <li>{{ item.budget.client.company_name }}</li>
            <li>{{ item.budget.client.trading_name }}</li>
          </ul>
        </td>
        <td>{{ item.budget.seller.name }}</td>
        <td>{{ item.print_name }}</td>
        <td>{{ getTotal(item.order_products) }}</td>
        <td class="text-center">
          <StatusChip
            :status="orderStatus"
            :currentStatus="item.order_status"
          />
        </td>
        <td class="text-right">{{ item.approved_order_date | formatDate }}</td>
        <td class="text-right">{{ item.delivery_date | formatDate }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    orderStatus: [
      { value: 1, text: 'Pedido Emitido', color: 'blue-grey' },
      { value: 2, text: 'Aprovação de cadastro', color: 'indigo' },
      { value: 3, text: 'Aprovação de pedido', color: 'cyan' },
      { value: 4, text: 'Compras', color: 'deep-purple' },
      { value: 5, text: 'Pedido finalizado', color: 'success' },
      { value: 6, text: 'Arquivado', color: 'greyy' },
      { value: 7, text: 'Cancelado', color: 'error' },
    ],
    dataTable: {
      title: 'Pedidos',
      new: 'orders-new',
      edit: 'orders-edit',
      context: 'orders-context',
      massAction: 'orders-mass-action',
      headerButtons: {
        filter: true,
        new: false,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Pedido', value: 'id', width: 30 },
        {
          text: 'Data de Registro',
          value: 'approved_registration_date',
          width: 200,
          align: 'center',
        },
        { text: 'Empresa', value: 'order_company_name' },
        { text: 'Cliente', value: 'order_client', width: 200 },
        { text: 'Vendedor' },
        { text: 'Nome p/ Gravação', value: 'print_name' },
        { text: 'Total', value: 'order_subtotal' },
        { text: 'Status', value: 'order_status', align: 'center' },
        {
          text: 'Data de Aprovação',
          value: 'approved_order_date',
          width: 200,
          align: 'end',
        },
        {
          text: 'Data de Entrega',
          value: 'delivery_date',
          width: 200,
          align: 'end',
        },
      ],
    },
  }),
  methods: {
    getOrders() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.types = this.$cookies.get('type')
      this.$api
        .get('orders', { types: this.types })
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterOrders(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('order/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getOrders()
    },
    getTotal(products) {
      let total = 0

      products.map((product) => {
        if (product.alt_qtty) {
          var un = product.alt_unitary

          if (un.includes(',')) {
            un = un.replace(',', '.')
            un = parseFloat(un)
          }

          total = total + product.alt_qtty * un
        } else {
          total = total + product.default_qtty * product.default_unitary
        }
      })

      return total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
  created: function () {
    this.getOrders()

    contextMenu.$once('action', (type) => {
      if (type === 'orders') {
        this.getOrders()
      }
    })
    massAction.$once('action', () => {
      this.getOrders()
    })
  },
}
</script>
